"use client";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { navLinks, testNavLinks } from "@/lib/links";
import { sosmed } from "@/lib/sosmed";
import { cn } from "@/lib/tailwind-utils";
import { Menu } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import SectionWrapper from "./section-wrapper";

export default function Navbar() {
  const [isOpenLainnyaDropdown, setIsOpenLainnyaDropdown] = useState(false);
  const [isOpenSheet, setIsOpenSheet] = useState(false);

  const pathname = usePathname();

  return (
    <header className="fixed w-full top-0 z-30 h-[80px] items-center border-b bg-background ">
      <SectionWrapper>
        <nav className="flex  justify-between w-full  gap-4 items-center h-full">
          <Link href="/" className="flex items-center">
            <div className="relative h-[60px] w-[60px]">
              <Image alt="" src="/images/bpp_tegal_new.png" fill priority />
            </div>
            <div className="relative h-[60px] w-[200px]">
              <Image
                src={"/images/simdiklat-font-logo.jpg"}
                fill
                alt=""
                priority
              />
            </div>
          </Link>
          <div className="hidden flex-col gap-6 text-lg font-medium lg:flex lg:flex-row lg:items-center  lg:text-sm lg:gap-6">
            {navLinks.map((link) => {
              if (link?.subLinks?.length > 0) {
                return (
                  <DropdownMenu
                    modal={false}
                    key={link.id}
                    open={isOpenLainnyaDropdown}
                    onOpenChange={setIsOpenLainnyaDropdown}
                  >
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant={"reset"}
                        className={cn(
                          "text-foreground p-0 transition-colors hover:text-color-4 hover:underline hover:underline-offset-4 flex items-center gap-1",
                          {
                            "text-primary":
                              pathname === "/cek-sertifikat" ||
                              pathname === "/kontak",
                          },
                        )}
                      >
                        Dukungan Lainnya{" "}
                        {isOpenLainnyaDropdown ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[400px] ">
                      {link.subLinks.map((linkSub) => {
                        return (
                          <DropdownMenuItem key={linkSub.id} asChild>
                            <Link
                              href={linkSub.href}
                              className={cn(
                                "cursor-pointer focus:text-color-4 focus:underline focus:underline-offset-4",
                                {
                                  "text-primary": pathname === linkSub.href,
                                },
                              )}
                            >
                              {linkSub.name}
                            </Link>
                          </DropdownMenuItem>
                        );
                      })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                );
              } else {
                return (
                  <Link
                    key={link.id}
                    href={link.href}
                    className={cn(
                      "text-txt14_20 text-foreground transition-colors hover:text-color-4 hover:underline hover:underline-offset-4",
                      {
                        "text-primary": pathname === link.href,
                      },
                    )}
                  >
                    {link.name}
                  </Link>
                );
              }
            })}
          </div>
          <div className="hidden flex-col gap-6 text-lg font-medium lg:flex lg:flex-row lg:items-center  lg:text-sm lg:gap-6">
            <div className="flex gap-2">
              {sosmed.map((data) => {
                if (data?.name !== "Tarif Layanan") {
                  if (!data.isHero) {
                    const Icon = data.icon;
                    return (
                      <Link
                        target="_blank"
                        key={data.id}
                        href={data.href}
                        className="group/sosmed flex h-[40px] w-[40px]  cursor-pointer items-center justify-center rounded-full bg-[#005CA1] hover:border-2 hover:border-[#005CA1] hover:bg-white"
                      >
                        <Icon className="h-[24px] w-[24px] text-white group-hover/sosmed:text-[#005CA1]" />
                      </Link>
                    );
                  }
                  return (
                    <Link
                      target="_blank"
                      key={data.id}
                      href={data.href}
                      className="group/sosmed flex h-[40px] w-[40px]  cursor-pointer items-center justify-center rounded-full bg-[#005CA1] hover:border-2  hover:border-[#005CA1] hover:bg-white"
                    >
                      <Image
                        width={32}
                        height={32}
                        src={data.imageRef}
                        className="h-[28px] w-[28px] bg-white rounded-full text-white group-hover/sosmed:text-[#005CA1]"
                      />
                    </Link>
                  );
                }
              })}
            </div>
          </div>
          <Sheet open={isOpenSheet} onOpenChange={setIsOpenSheet}>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="shrink-0 lg:hidden"
              >
                <Menu className="h-5 w-5" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <nav className="grid gap-6 text-lg font-medium">
                <Link href="/" className="flex items-center">
                  <div className="relative h-[40px] w-[40px]">
                    <Image
                      alt=""
                      src="/images/bpp_tegal_new.png"
                      fill
                      priority
                    />
                  </div>
                  <div className="relative h-[40px] w-[160px]">
                    <Image
                      src={"/images/simdiklat-font-logo.jpg"}
                      fill
                      alt=""
                      priority
                    />
                  </div>
                </Link>
                {testNavLinks.map((link) => {
                  if (link?.subLinks?.length > 0) {
                    return link.subLinks.map((linkSub) => {
                      return (
                        <Link
                          onClick={() => setIsOpenSheet(false)}
                          key={linkSub.id}
                          href={linkSub.href}
                          className="cursor-pointer hover:text-color-4 hover:underline hover:underline-offset-4"
                        >
                          {linkSub.name}
                        </Link>
                      );
                    });
                  } else {
                    return (
                      <Link
                        onClick={() => setIsOpenSheet(false)}
                        key={link.id}
                        href={link.href}
                        className="text-foreground hover:text-color-4  transition-colors  hover:underline hover:underline-offset-4"
                      >
                        {link.name}
                      </Link>
                    );
                  }
                })}
              </nav>
            </SheetContent>
          </Sheet>
        </nav>
      </SectionWrapper>
    </header>
  );
}
