import(/* webpackMode: "eager" */ "/home/fe-silangit-app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/fe-silangit-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/fe-silangit-app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/fe-silangit-app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/fe-silangit-app/src/components/common/help-us.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/fe-silangit-app/src/components/common/navbar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/fe-silangit-app/src/components/provider/np-progress-bar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/fe-silangit-app/src/components/provider/react-query.jsx");
