"use client"

import { AppProgressBar as ProgressBar } from "next-nprogress-bar"
import { Suspense } from "react"

const NPProgresBarProviders = ({ children }) => {
  return (
    <>
      {children}
      <Suspense>
        <ProgressBar
          height="4px"
          color="#005CA1"
          options={{ showSpinner: false }}
          shallowRouting
        />
      </Suspense>
    </>
  )
}

export default NPProgresBarProviders
