import {
  FaFacebook,
  FaInstagram,
  FaMoneyBillWaveAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { TbTools } from "react-icons/tb";
import { GoGraph } from "react-icons/go";

export const sosmed = [
  {
    id: 0,
    name: "Dashboard Layanan",
    href: "https://simkeu.bppptegal.id/display",
    icon: GoGraph,
    imageRef: "/images/simdiklat-logo.png",
    isHero: false,
  },
  {
    id: 1,
    name: "Admin Silangit",
    href: "https://admin.bppptegal.id",
    icon: TbTools,
    imageRef: "/images/simdiklat-logo.png",
    isHero: false,
  },
  {
    id: 2,
    name: "Simkeu",
    href: "https://simkeu.bppptegal.id",
    icon: FaMoneyBillTransfer,
    imageRef: "/images/simdiklat-logo.png",
    isHero: false,
  },
  {
    id: 3,
    name: "Facebook",
    href: "https://www.facebook.com/p/BLU-BPPP-TEGAL-100086103392252",
    icon: FaFacebook,
    isHero: false,
  },
  {
    id: 4,
    name: "Instagram",
    href: "https://www.instagram.com/bppsdm_bp3tegal",
    icon: FaInstagram,
    isHero: false,
  },
  {
    id: 5,
    name: "Youtube",
    href: "https://www.youtube.com/channel/UCuwwSQATTDRez8hQipcss8g",
    icon: FaYoutube,
    isHero: false,
  },
  {
    id: 6,
    name: "Twitter",
    href: "https://twitter.com/pkblu_bp3tegal",
    icon: FaTwitter,
    isHero: false,
  },
  {
    id: 7,
    name: "Tarif Layanan",
    href: "/files/SK_TARIF_1_Mei_2024_STEMPEL.pdf",
    icon: FaMoneyBillWaveAlt,
    isHero: false,
  },
];
