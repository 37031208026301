import { cn } from "@/lib/tailwind-utils"

export default function SectionWrapper({ className, children }) {
  return (
    <div
      className={cn(" max-w-screen-xl mx-auto px-5 lg:px-6 h-full", className)}>
      {children}
    </div>
  )
}
