"use client"

import { cn } from "@/lib/tailwind-utils"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { FaWhatsapp } from "react-icons/fa"
import { FaHeadset } from "react-icons/fa6"

const hrefWA =
  "https://wa.me/6285229654056?text=Hallo, saya adalah pendaftar dari BPPP Tegal. Saya mohon bantuan karena terdapat kendala pada website ini."

const hrefBppTegalWA =
  "https://wa.me/6281326906109?text=Hallo, saya adalah pendaftar dari BPPP Tegal. Saya mohon informasi terkait bantuan pada saat pendaftaran."

const HelpUs = () => {
  const pathname = usePathname()

  return (
    <div
      className={cn(
        "flex flex-col gap-y-3 fixed bottom-[20px]  z-50 md:bottom-3  md:p-3 ",
        {
          "left-3 items-start  ": pathname?.includes("daftar"),
        },
        {
          "right-3 items-end  ": !pathname?.includes("daftar"),
        },
      )}>
      <p className="text-white hidden md:block bg-color-4 text-center p-2 text-txt10_14 md:text-txt14_20 rounded-md border-white border">
        Hallo, BPPP Tegal <span className="block">Saya perlu bantuan !</span>
      </p>
      <Link
        href={hrefWA}
        className=" w-max flex items-center rounded-md justify-center border-2 border-white bg-baseBlue2 p-2 text-white  ">
        <FaHeadset className="h-8 w-8 " />
        {/* <span className="text-sm">Bantuan</span> */}
      </Link>
      <Link
        href={hrefBppTegalWA}
        className=" flex w-max  items-center justify-center rounded-md border-2 border-white bg-[#128c7e] p-2 text-white  ">
        <FaWhatsapp className="h-8 w-8 " />
        {/* <span className="text-sm">Bantuan</span> */}
      </Link>
    </div>
  )
}

export default HelpUs
