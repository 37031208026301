export const navLinks = [
  {
    id: 1,
    href: "/",
    name: "Beranda",
    subLinks: [],
  },
  {
    id: 2,
    href: "/pelatihan",
    name: "Pelatihan",
    subLinks: [],
  },
  {
    id: 3,
    href: "/cek-sertifikat",
    name: "Cek Sertifikat",
    subLinks: [],
  },
  {
    id: 4,
    href: "",
    name: "Dukungan lainnya",
    subLinks: [
      {
        id: 5,
        href: "/tes-buta-warna",
        name: "Tes Buta Warna",
      },
      {
        id: 6,
        href: "/persiapan-pelatihan",
        name: "Persiapan Pelatihan",
      },
      {
        id: 7,
        href: "/kontak",
        name: "Kontak",
      },
      {
        id: 8,
        href: "/tentang-kami",
        name: "Tentang Kami",
      },
    ],
  },
]

export const secondNavlinks = navLinks.map((link) => {
  if (link.subLinks) {
    return {
      ...link,
      id: link.id + 10,
      subLinks: link.subLinks.map((sublink) => {
        return { ...sublink, id: sublink.id + 10 }
      }),
    }
  } else {
    return { ...link, id: link.id + 10 }
  }
})

export const testNavLinks = [
  {
    id: 10,
    href: "/",
    name: "Beranda",
    subLinks: [],
  },
  {
    id: 11,
    href: "/pelatihan",
    name: "Pelatihan",
    subLinks: [],
  },
  {
    id: 12,
    href: "/cek-sertifikat",
    name: "Cek Sertifikat",
    subLinks: [],
  },
  {
    id: 13,
    href: "",
    name: "Dukungan lainnya",
    subLinks: [
      {
        id: 14,
        href: "/tes-buta-warna",
        name: "Tes Buta Warna",
      },
      {
        id: 15,
        href: "/persiapan-pelatihan",
        name: "Persiapan Pelatihan",
      },
      {
        id: 16,
        href: "/kontak",
        name: "Kontak",
      },
      {
        id: 17,
        href: "/tentang-kami",
        name: "Tentang Kami",
      },
    ],
  },
]

export const footerLinks = [
  {
    id: 1,
    href: "/",
    name: "Beranda",
  },
  {
    id: 2,
    href: "/pelatihan",
    name: "Pelatihan",
  },
  {
    id: 3,
    href: "/tentang-kami",
    name: "Tentang Kami",
  },
  {
    id: 4,
    href: "/cek-sertifikat",
    name: "Cek Sertifikat",
  },
  {
    id: 5,
    href: "/kontak",
    name: "Kontak",
  },
]
